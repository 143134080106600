.link__project__wrap {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    padding: 2.5%;
}

.link__project__content__wrap {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: calc(100% - 3.5vmin);
    box-sizing: border-box;
    padding: 2.5% 0;
}

.link__project__content {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    position: relative;
    justify-content: space-between;
}

.link__project__content::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 1px;
    content: '';
    background: #fff;
    background-position: 0% 0%;
    background: linear-gradient(to right, #B294FF, #57E6E6, #FEFFB8, #57E6E6, #B294FF, #57E6E6);
    background-size: 500% auto;
    animation: color-run 3s linear infinite;
}

@keyframes color-run {
    0% {
        background-position: 0 0
    }

    100% {
        background-position: 100% 0
    }
}

.link__project__title {
    width: 100%;
    height: 5vmin;
    margin-bottom: 2.5%;
}

.link__project__title span {
    font-size: 3vmin;
    color: #fff;
    letter-spacing: 2.5px;
    font-family: 'Electrolize', sans-serif;
}

.link__project__introduction,
.link__project__documents {
    display: flex;
    width: 45%;
    height: 100%;
    flex-direction: column;
    box-sizing: border-box;
    padding: 2.5% 0;
}

.link__project__detail {
    display: flex;
    width: 100%;
    height: 25%;
    flex-direction: column;
}

.link__project__detail span {
    display: inline-block;
    font-size: 2vmin;
    color: rgba(255, 255, 255, .5);
    letter-spacing: 1.5px;
    line-height: 3vmin;
    text-align: justify;
    margin-bottom: 2.5vmin;
}

.link__project__doc__link {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 2.5vmin;
}

.link__project__doc__dot {
    width: 10px;
    height: 10px;
    background-color: rgba(255, 255, 255, .5);
    margin-right: 1.5vmin;
    transition: .5s linear all;
}

.link__project__doc__link span {
    font-size: 2vmin;
    color: rgba(255, 255, 255, .5);
    letter-spacing: 1.5px;
    font-family: 'Electrolize', sans-serif;
    transition: .5s linear all;
}

.link__project__doc__link:hover .link__project__doc__dot {
    background-color: rgba(255, 255, 255, 1);
    transition: .5s linear all;
}

.link__project__doc__link:hover span {
    color: rgba(255, 255, 255, 1);
    transition: .5s linear all;
}

.link__project__doc__link a {
    text-decoration: none;
}

.link__project__half__line {
    width: 2px;
    height: 55vh;
    position: absolute;
    top: 62.5%;
    left: 50%;
    transform: translate(-50%, -62.5%);
    opacity: .5;
}

.link__project__appliance {
    width: 100%;
    height: calc(75% - 5vmin);
}

.link__project__appliance__title {
    width: 100%;
    height: 3vmin;
}

.link__project__appliance__title span {
    display: inline-block;
    font-size: 2vmin;
    color: rgba(255, 255, 255, 0.5);
    letter-spacing: 1.5px;
    line-height: 3vmin;
    font-family: 'Electrolize', sans-serif;
}

.link__project__devices__group {
    margin-top: 1vmin;
    height: 30%;
    height: calc(100% - 3vmin);
    overflow-y: auto;
    &::-webkit-scrollbar-track { // 軌道
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 1rem;
        opacity: 0.1;
    }
    &::-webkit-scrollbar
    {
        height: 0px;
        width: 0px;
        border-radius: 1rem;
        background-color: rgba(245, 245, 245, .3);
    }
    &::-webkit-scrollbar-thumb
    {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: rgb(177, 177, 177);
    }
}

.link__project__device {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 1px;
    margin-bottom: 2.5vmin;
    opacity: .7;
    transition: all .2s linear;
    width: calc(100% - 10px);
    cursor: pointer;
}

.link__project__device img {
    height: 15vmin;
    width: 30%;
    vertical-align: bottom;
    object-fit: contain;
    margin: 0vmin 2.5vmin;
}

.link__project__device span {
    font-size: 2vmin;
    color: #fff;
    letter-spacing: 2.5px;
    font-family: 'Electrolize', sans-serif;
}

.link__lanner__psp__appliance:hover {
    .link__project__device,
    .link__lanner__psp__copy {
        opacity: 1;
        transition: all .2s linear;
        box-shadow: 3px 3px 10px rgba(255, 255, 255, .5);
        border: 1px solid rgba(255, 255, 255, .5);
    }
    .link__lanner__psp__copy {
        color: #fff;
    }
}

.link__project__device:hover {
    opacity: 1;
    transition: all .2s linear;
    box-shadow: 3px 3px 10px rgba(255, 255, 255, .5);
    border: 1px solid rgba(255, 255, 255, .5);
}


.link__lanner__psp__appliance {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.link__psp__device {
    width: 80%;
}

.link__lanner__psp__copy {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 15%;
    height: 15vmin;
    border: 1px solid transparent;
    color: #000;
    font-size: 1vmin;
    letter-spacing: 0.5px;
    font-family: 'Electrolize', sans-serif;
    border-radius: 1px;
    margin-bottom: 2.5vmin;
    opacity: 0;
    cursor: pointer;
    span {
        margin: 2.5px 0px;
        user-select: none;
        pointer-events: none;
    }
}
.link__psp__description {
    height: calc(100% - 5vmin);
    overflow-y: auto;
    &::-webkit-scrollbar-track { // 軌道
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 1rem;
        opacity: 0.1;
    }
    &::-webkit-scrollbar
    {
        height: 0px;
        width: 0px;
        border-radius: 1rem;
        background-color: rgba(245, 245, 245, .3);
    }
    &::-webkit-scrollbar-thumb
    {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: rgb(177, 177, 177);
    }
}

.link__psp__paragraph {
    margin-bottom: 5vmin;
}

.link__psp__title span {
    font-size: 2.5vmin;
    letter-spacing: 1px;
    font-family: 'Electrolize', sans-serif;
    color: rgba(255, 255, 255, .75);
    position: relative;
    transition: .5s linear;
}

.link__psp__content p {
    font-size: 1.5vmin;
    letter-spacing: 1.5px;
    color: rgba(255, 255, 255, .5);
    position: relative;
    text-align: justify;
}
.link__psp__content ul li {
    font-size: 1.5vmin;
    letter-spacing: 1.5px;
    color: rgba(255, 255, 255, .5);
    position: relative;
    text-align: justify;
}
.link__psp__diff__table {
    display: flex;
    flex-direction: column;
    width: 100%;
    span {
        font-size: 1vmin;
    }
}
.link__psp__diff__table__head,
.link__psp__diff__table__body__line {
    display: flex;
    flex-direction: row;
}
.link__psp__diff__table__head__item {
    width: calc(100% / 7);
    height: 2.5vmin;
    background-color: rgba(255, 255, 255, .5);
    color: #444;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box
}
.link__psp__diff__table__body__item {
    @extend .link__psp__diff__table__head__item;
    background-color: unset;
    color: rgba(255, 255, 255, .5);
    height: 5vmin;
    border-bottom: 1px solid rgba(255, 255, 255, .5);
    // border-right: 1px solid rgba(255, 255, 255, .5);
}
.link__psp__diff__table__head__item:nth-of-type(1) {
    justify-content: flex-start;
    padding-left: 1vmin;
    // border-left: 1px solid rgba(255, 255, 255, .5);
}
// .link__psp__diff__table__head__item:nth-of-type(2) {
//     width: 20%;
// }
// .link__psp__diff__table__head__item:nth-of-type(3) {
//     width: 20%;
// }
// .link__psp__diff__table__head__item:nth-of-type(4),
// .link__psp__diff__table__head__item:nth-of-type(5),
// .link__psp__diff__table__head__item:nth-of-type(6),
// .link__psp__diff__table__head__item:nth-of-type(7) {
//     width: 6%;
// }