.link__section {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000000;
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.infinity__filter {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 1);
    z-index: 10;
    backdrop-filter: blur(3px);
    animation: filter-fade-out 2s linear 5s forwards;
}

@keyframes filter-fade-out {
    from {
        background-color: rgba(0, 0, 0, 1);
    }

    to {
        background-color: rgba(0, 0, 0, .2);
    }
}

.infinity__wrap {
    display: flex;
    z-index: 1;
    opacity: .75;
}

.link__init__animation {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 50%;
    transform: translate(-50%, -50%);
    z-index: 16;
}

.link__init__circle {
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
}

.link__init__center__point {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0px;
    height: 0px;
    background-color: transparent;
}

.link__init__center__point::before {
    content: '';
    width: 0vw;
    height: 1px;
    position: absolute;
    opacity: 0;
    top: 0;
    right: 0;
    background: linear-gradient(to left, #01FF89, #7A5FFF);
    box-shadow: 0px 0px 15px 1px #7A5FFF, 0px 0px 25px 1px #01FF89;
    animation:
        lining 1s ease-in-out forwards 1.75s,
        hidden-lines .5s linear forwards 3s;
}

.link__init__center__point::after {
    content: '';
    width: 0vw;
    height: 1px;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    background: linear-gradient(to left, #7A5FFF, #01FF89);
    box-shadow: 0px 0px 15px 1px #7A5FFF, 0px 0px 25px 1px #01FF89;
    animation:
        lining 1s ease-in-out forwards 1.75s,
        hidden-lines .5s linear forwards 3s;
}

@keyframes lining {
    from {
        width: 0vw;
        opacity: 1;
    }

    to {
        width: 50vw;
        opacity: 1;
    }
}

@keyframes hidden-lines {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}


.link__init__circle:nth-child(1) {
    width: 1vmin;
    height: 1vmin;
}

.link__init__circle:nth-child(2) {
    width: 2vmin;
    height: 2vmin;
}

.link__init__circle:nth-child(1) {
    border: 3px solid #7A5FFF;
    box-shadow: 0 0 20px #7A5FFF, 0 0 40px #7A5FFF;
    animation: circle-out 1.5s ease-in-out .5s forwards;
}

.link__init__circle:nth-child(2) {
    border: 3px solid #01FF89;
    box-shadow: 0 0 20px #01FF89, 0 0 40px #01FF89;
    animation: circle-out 1.5s linear .5s forwards;
}

.link__init__circle__content {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: transparent;
}

@keyframes circle-out {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
    }

    30% {
        opacity: .3;
    }

    40% {
        opacity: 1;
    }

    50% {
        opacity: .3;
    }

    75% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.infinity__svg {
    display: flex;
    width: 35vw;
    height: 100%;
    will-change: auto;
    opacity: 0;
    animation: infinity-animation 1.5s linear 7.5s forwards;

    svg {
        stroke-width: 2.5px;
        stroke: rgb(255, 255, 255);
        fill: transparent;
        opacity: 0.85;
    }
}

@keyframes infinity-animation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.infinity__svg svg path {
    stroke-dasharray: 2914;
    stroke-dashoffset: 2914;
}

@keyframes draw-infinity {
    from {
        stroke-dashoffset: 2914;
    }

    to {
        stroke-dashoffset: 0;
    }
}

.link__logo__svg {
    display: flex;
    width: 30vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 15;

    svg {
        stroke-width: 1.5px;
        fill: transparent;
        stroke: rgb(255, 255, 255);
    }
}

// N
.link__logo__svg svg path:nth-child(1) {
    stroke-dasharray: 1360;
    stroke-dashoffset: 1360;
    animation: logo-drawing 2s linear 3.5s forwards,
        logo-filling 1s linear 6.5s forwards;
}

// L
.link__logo__svg svg path:nth-child(2) {
    stroke-dasharray: 1360;
    stroke-dashoffset: 1360;
    animation: logo-drawing 2.5s linear 3.5s forwards,
        logo-filling 1s linear 6s forwards;
}

// K <
.link__logo__svg svg path:nth-child(3) {
    stroke-dasharray: 1360;
    stroke-dashoffset: 1360;
    animation: logo-drawing 2.5s linear 3.5s forwards,
        logo-filling 1s linear 7s forwards;
}

// I
.link__logo__svg svg path:nth-child(4) {
    stroke-dasharray: 1360;
    stroke-dashoffset: 1360;
    animation: logo-drawing 2.5s linear 3.5s forwards,
        logo-filling 1s linear 6.25s forwards;
}

// K I
.link__logo__svg svg path:nth-child(5) {
    stroke-dasharray: 1360;
    stroke-dashoffset: 1360;
    animation: logo-drawing 2.5s linear 3.5s forwards,
        logo-filling 1s linear 6.75s forwards;
}

// M
.link__logo__svg svg path:nth-child(6) {
    stroke-dasharray: 1360;
    stroke-dashoffset: 1360;
    animation: logo-drawing 2.5s linear 5.5s forwards,
        logo-filling 1s linear 7.5s forwards;
}

// T
.link__logo__svg svg path:nth-child(7) {
    stroke-dasharray: 1360;
    stroke-dashoffset: 1360;
    animation: logo-drawing 2.5s linear 5.5s forwards,
        logo-filling 1s linear 7.25s forwards;
}

@keyframes logo-drawing {
    from {
        stroke-dashoffset: 1360;
    }

    to {
        stroke-dashoffset: 0;
    }
}

@keyframes logo-filling {
    from {
        fill: transparent;
    }

    to {
        fill: rgba(255, 255, 255, .75);
    }
}

.link__logo__desc__svg {
    display: flex;
    justify-content: center;
    width: 20vw;
    height: auto;
    position: absolute;
    // top: 62.5%;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -62.5%);
    z-index: 15;
    border-radius: 3px;
    opacity: 0;
    animation: sub-logo-animation 1s linear 9s forwards;

    svg {
        width: 100%;
        stroke-width: 2.5px;
        stroke: rgb(255, 255, 255);
        opacity: 0.85;
        fill: transparent;
    }
}

@keyframes sub-logo-animation {
    from {
        opacity: 0;
        top: 65%;
    }

    to {
        opacity: 1;
        top: 62.5%;
    }
}

.scroll__down__wrap {
    position: absolute;
    left: 50%;
    bottom: 17.5%;
    transform: translate(-50%, -15%);
    z-index: 10;
    opacity: 0;
    animation: down-arrow-show 1s linear 10.5s forwards;
}

@keyframes down-arrow-show {
    from {
        opacity: 0;
        bottom: 17.5%;
    }

    to {
        opacity: 1;
        bottom: 15%;
    }
}

$base: 0.6rem;

.arrow {
    position: absolute;
    width: $base * 3.5;
    height: $base * 0.8;
    opacity: 0;
    transform: scale(0.3);
    animation: move-arrow 3s ease-out infinite;
}

.arrow:first-child {
    animation: move-arrow 3s ease-out 1s infinite;
}

.arrow:nth-child(2) {
    animation: move-arrow 3s ease-out 2s infinite;
}

.arrow:before,
.arrow:after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 50%;
    background: rgba(255, 255, 255, .75)
}

.arrow:before {
    left: 0;
    transform: skewY(30deg);
}

.arrow:after {
    right: 0;
    width: 50%;
    transform: skewY(-30deg);
}

@keyframes move-arrow {
    25% {
        opacity: 1;
    }

    33.3% {
        opacity: 1;
        transform: translateY($base * 3.8);
    }

    66.6% {
        opacity: 1;
        transform: translateY($base * 5.2);
    }

    100% {
        opacity: 0;
        transform: translateY($base * 8) scale(0.5);
    }
}