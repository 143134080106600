.link__main__wrap {
    overflow-x: hidden;
    overflow-y: auto;
    --scroll-bar: 0;
}

.link__main__wrap {
    &::-webkit-scrollbar-track {
        // 軌道
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 1rem;
        opacity: 0.1;
    }

    &::-webkit-scrollbar {
        height: 0px;
        width: 0px;
        border-radius: 1rem;
        background-color: rgba(245, 245, 245, .3);
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: rgb(177, 177, 177);
    }
}