.link__head__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 3.5vmin;
    position: relative;
}

.link__menu__icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.link__menu__icon--menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.link__menu__icon--close {
    @extend .link__menu__icon--menu;
}

.link__menu__icon--menu .menu__bar {
    width: 1.5vmax;
    height: .25vmin;
    background-color: #fff;
    margin-bottom: 5.5px;
    transition: .5s linear all;
}

.link__menu__icon--menu .menu__bar:nth-child(3) {
    margin-bottom: 0px;
}

.link__menu__icon--close .menu__bar:nth-child(1) {
    transform: rotate(45deg) translateX(11px);
    transition: .5s linear all;
}

.link__menu__icon--close .menu__bar:nth-child(2) {
    opacity: 0;
    transition: .5s linear all;
}

.link__menu__icon--close .menu__bar:nth-child(3) {
    transform: rotate(-45deg) translateX(11px);
    margin-bottom: 0px;
    transition: .5s linear all;
}


.link__full__menu {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 95vw;
    height: calc(100vh - 3.5vmin);
    background-color: #000;
    z-index: 2;
    box-sizing: border-box;
    padding: 2.5%;
    transition: .5s linear all;
}

.link__full__menu--open {
    @extend .link__full__menu;
    left: 0;
    top: 100%;
    position: absolute;
    transition: .5s linear all;
}

.link__full__menu--hidden {
    @extend .link__full__menu;
    left: 0;
    top: 100vh;
    position: absolute;
    transition: .5s linear all;
}

.link__full__menu span {
    font-size: 3.5vmin;
    font-family: 'Electrolize', sans-serif;
    letter-spacing: 2px;
    position: relative;
    color: rgba(255, 255, 255, .5);
    transition: .2s linear all;
    cursor: pointer;
    user-select: none;
}

.link__full__menu span:hover {
    color: rgba(255, 255, 255, 1);
    transition: .2s linear all;
}

.link__full__menu span::after {
    content: '';
    width: 0%;
    height: 2px;
    position: absolute;
    bottom: -5px;
    left: 0;
    background-color: #fff;
    transition: .2s linear all;
}


.link__full__menu span:hover::after {
    width: 100%;
    transition: .2s linear all;
}