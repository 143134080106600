.link__page__404 {
    display: flex;
    width: 100vw;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
}

.link__404__text {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 25%;
}

.link__404__text span {
    color: rgb(170, 170, 170);
    letter-spacing: 1.5px;
    font-family: 'Electrolize', sans-serif;
    font-size: 2.5vmin;
}