.link__menu__wrap {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    background: linear-gradient(to bottom, rgba(0, 0, 0, .8), transparent);
}

a {
    text-decoration: none;
}

.link__menu__title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50%;

    @media only screen and (max-width:768px) {
        height: 10%;
    }
}

.link__menu__title span {
    font-size: 4.5vmin;
    letter-spacing: 1px;
    font-family: 'Electrolize', sans-serif;
    color: rgba(255, 255, 255, .5);
    position: relative;
}

.link__menu__title span::before {
    content: '';
    position: absolute;
    bottom: 0%;
    left: -50%;
    width: 200%;
    height: 1px;
    background: linear-gradient(to right, #e9f7f8 25%, #14a38e);
}

.link__menu__title span::after {
    content: '';
    position: absolute;
    bottom: -20%;
    left: 0%;
    width: 100%;
    height: 1px;
    background: linear-gradient(to left, #e9f7f8 25%, #14a38e);
}

.link__menu__group {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
    height: 50%;
    overflow: hidden;

    @media only screen and (max-width:768px) {
        flex-direction: column;
        align-items: center;
        height: 80%;
    }
}


.link__menu__card__wrap {
    position: relative;
    width: 13vmax;
    height: 7.25vmin;
    cursor: pointer;
    transition: .5s all;
    box-shadow: 3px 3px 15px rgb(83, 83, 83);

    @media only screen and (max-width:768px) {
        width: 50vmin;
        height: 10vmin;
    }
}

.link__menu__card__border {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    border-radius: 3px;
    border: 1px solid rgba(255, 255, 255, .5);
    transition: .5s linear all;

    &:hover {
        border-color: transparent;
        transition: .5s linear all;
    }
}


.link__menu__card__content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 5px);
    height: calc(100% - 5px);
    position: absolute;
    border-radius: 3px;
    top: 50%;
    left: 50%;
    background-color: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(2.5px);
    transform: translate(-50%, -50%);
    z-index: 10;
    border: 1.5px solid transparent;
    transition: .5s linear all;

    span {
        text-align: center;
        font-size: 1.75vmin;
        letter-spacing: 1.5px;
        font-family: 'Electrolize', sans-serif;
        color: rgba(255, 255, 255, .5);
        position: relative;
    }
}

.link__menu__card__content:hover {
    span {
        color: rgba(255, 255, 255, 1);
    }
}

#ma:hover>.link__menu__card__border {
    transition: .5s linear all;
    background: #1489a3;
    background: -webkit-linear-gradient(135deg, #1489a3 25%, #f20c0c);
    background: linear-gradient(135deg, #1489a3 25%, #f20c0c);
}

#ma:hover>.link__menu__card__border>.link__menu__card__content span::after {
    content: '';
    height: 1.5px;
    width: 100%;
    position: absolute;
    bottom: -1vmin;
    left: 0;
    background-color: rgba(0, 208, 255, .5);
    transition: .5s linear all;
}

#re:hover>.link__menu__card__border {
    transition: .5s linear all;
    background: #14a367;
    background: -webkit-linear-gradient(135deg, #14a367 25%, #bc0cf2);
    background: linear-gradient(135deg, #14a367 25%, #bc0cf2);
}

#re:hover>.link__menu__card__border>.link__menu__card__content span::after {
    content: '';
    height: 1.5px;
    width: 100%;
    position: absolute;
    bottom: -1vmin;
    left: 0;
    background-color: rgba(0, 255, 179, 0.5);
    transition: .5s linear all;
}

#se:hover>.link__menu__card__border {
    transition: .5s linear all;
    background: #b17315;
    background: -webkit-linear-gradient(135deg, #b17315 25%, #1499a3);
    background: linear-gradient(135deg, #b17315 25%, #1499a3);
}

#se:hover>.link__menu__card__border>.link__menu__card__content span::after {
    content: '';
    height: 1.5px;
    width: 100%;
    position: absolute;
    bottom: -1vmin;
    left: 0;
    background-color: rgba(255, 162, 0, 0.5);
    transition: .5s linear all;
}

#pe:hover>.link__menu__card__border {
    transition: .5s linear all;
    background: #2514a3;
    background: -webkit-linear-gradient(135deg, #2514a3 15%, #14a35b);
    background: linear-gradient(135deg, #2514a3 15%, #14a35b);
}

#pe:hover>.link__menu__card__border>.link__menu__card__content span::after {
    content: '';
    height: 1.5px;
    width: 100%;
    position: absolute;
    bottom: -1vmin;
    left: 0;
    background-color: rgba(82, 84, 255, 0.5);
    transition: .5s linear all;
}

#de:hover>.link__menu__card__border {
    transition: .5s linear all;
    background: #a33a14;
    background: -webkit-linear-gradient(135deg, #a33a14 25%, #e0eb0d);
    background: linear-gradient(135deg, #a33a14 25%, #e0eb0d);
}

#de:hover>.link__menu__card__border>.link__menu__card__content span::after {
    content: '';
    height: 1.5px;
    width: 100%;
    position: absolute;
    bottom: -1vmin;
    left: 0;
    background-color: rgba(255, 251, 0, 0.5);
    transition: .5s linear all;
}

#cm:hover>.link__menu__card__border {
    transition: .5s linear all;
    background: #14a38e;
    background: -webkit-linear-gradient(135deg, #e9f7f8 25%, #14a38e);
    background: linear-gradient(135deg, #e9f7f8 25%, #14a38e);
}

#cm:hover>.link__menu__card__border>.link__menu__card__content span::after {
    content: '';
    height: 1.5px;
    width: 100%;
    position: absolute;
    bottom: -1vmin;
    left: 0;
    background-color: rgba(181, 243, 241, 0.5);
    transition: .5s linear all;
}