.link__products__wrap {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    padding: 5% 10%;
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, .8));
}

.link__products__title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 10%;

    @media only screen and (max-width:768px) {
        justify-content: center;
    }
}

.link__products__swiper {
    width: 100%;
    height: 90%;
}

.link__products__title span {
    font-size: 4.5vmin;
    letter-spacing: 1px;
    font-family: 'Electrolize', sans-serif;
    color: rgba(255, 255, 255, .5);
    position: relative;
}

.link__products__title span::before {
    content: '';
    position: absolute;
    bottom: 0%;
    left: 0%;
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, #e9f7f8 25%, #14a38e);

    @media only screen and (max-width:768px) {
        bottom: 0%;
        left: -25%;
        width: 150%;
    }
}

.link__products__title span::after {
    content: '';
    position: absolute;
    bottom: -10%;
    left: 0%;
    width: 175%;
    height: 1px;
    background: linear-gradient(to left, #e9f7f8 25%, #14a38e);

    @media only screen and (max-width:768px) {
        bottom: -20%;
        left: 0%;
        width: 100%;
    }
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    background: transparent;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-button-prev {
    color: #e9f7f8;
}

.swiper-button-next {
    color: #14a38e;
}

.swiper-pagination-bullet {
    background-color: #dddddd;
}

.swiper-pagination-bullet-active {
    background-color: #57E6E6;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper {
    margin-left: auto;
    margin-right: auto;
}

.link__product__card {
    width: 70%;
    height: 70%;
    position: relative;
}

.link__product__card__bg {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(135deg, #e9f7f8 25%, #14a38e);
    z-index: 1;
    box-shadow: 3px 3px 10px rgba(20, 163, 142, .5);
}

.link__product__card__content {
    background-color: rgba(0, 0, 0, .85);
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid transparent;
    z-index: 2;
    display: flex;
    flex-direction: row;

    @media only screen and (max-width:768px) {
        flex-direction: column;
    }
}

.content__empty {
    display: flex;
    justify-content: center;
    align-items: center;
}

.content__empty span {
    font-size: 2.5vmin;
    letter-spacing: 1px;
    font-family: 'Electrolize', sans-serif;
    color: rgba(255, 255, 255, .5);
    position: relative;
}

.link__product__title {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: calc(100% / 3);
    background: #000000;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.link__product__image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 100%;
    position: relative;

    @media only screen and (max-width:768px) {
        width: 100%;
    }
}

.link__product__more {
    color: rgba(255, 255, 255, .5);
    font-size: 1.5vmin;
    letter-spacing: 1px;
    font-family: 'Electrolize', sans-serif;
    cursor: pointer;
    user-select: none;
    position: absolute;
    transition: .2s linear;
    left: 50%;
    bottom: 15%;
    transform: translate(-50%, 15%);
}

.link__product__more:hover {
    color: rgba(255, 255, 255, 1);
    transition: .2s linear;
}

.link__product__image a {
    text-decoration: none;
    color: inherit;
}

.link__product__image a:visited {
    text-decoration: none;
    color: inherit;
}

.LEC-7242 {
    width: 100%;
    height: 50%;
    background-size: cover;
    background-image: url('../../images/lec7242.png');
}

.link__product__detail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 60%;
    height: 100%;
    overflow-x: visible !important;
    overflow-y: scroll;
    box-sizing: border-box;
    padding: 5%;

    &::-webkit-scrollbar-track {
        // 軌道
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 1rem;
        opacity: 0.1;
    }

    &::-webkit-scrollbar {
        height: 0px;
        width: 0px;
        border-radius: 1rem;
        background-color: rgba(245, 245, 245, .3);
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: rgb(177, 177, 177);
    }

    @media only screen and (max-width:768px) {
        width: 100%;
        margin-top: 10vmin;
    }
}

.link__product__name {
    font-size: 2.5vmin;
    letter-spacing: 1.5px;
    font-family: 'Electrolize', sans-serif;
    color: rgba(252, 252, 252, 0.75);
    position: relative;
    margin-bottom: 2.5vmin;

    @media only screen and (max-width:768px) {
        margin-bottom: 5vmin;
    }
}

.link__product__name::after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: rgba(252, 252, 252, 0.75);
    position: absolute;
    left: 0%;
    bottom: -5%;
}

.link__product__description {
    font-size: 1.5vmin;
    font-style: italic;
    letter-spacing: 1.5px;
    font-family: 'Electrolize', sans-serif;
    color: rgba(252, 252, 252, 0.75);
    position: relative;
    margin-bottom: 2.5vmin;
}

.link__product__detail ul {
    padding-left: 2vmin;
    margin: 0;
}

.link__product__detail ul li {
    font-size: 1.75vmin;
    letter-spacing: 1.5px;
    font-family: 'Electrolize', sans-serif;
    color: rgba(252, 252, 252, 0.75);
    position: relative;
    margin-bottom: 2.5vmin;
}

.link__product__detail ul li:nth-last-child(1) {
    margin-bottom: 0vmin;
}

.link__product__line {
    width: 0px;
    height: 75%;
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-40%, -50%);
    background-color: transparent;

    @media only screen and (max-width:768px) {
        width: 75%;
        height: 0px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.link__product__line::after {
    content: "";
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 9;
    height: 100%;
    width: 2px;
    background-position: 0% 0%;
    background: linear-gradient(to right, #B294FF, #57E6E6, #FEFFB8, #57E6E6, #B294FF, #57E6E6);
    background-size: 500% auto;
    animation: try 3s linear infinite;

    @media only screen and (max-width:768px) {
        width: 100%;
        height: 2px;
    }
}

@keyframes try {
    0% {
        background-position: 0 0
    }

    100% {
        background-position: 100% 0
    }
}