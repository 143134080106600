.link__contact__wrap {
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-image: linear-gradient(67.5deg, rgb(6, 6, 6) 0%, rgb(6, 6, 6) 6%, rgb(29, 29, 29) 6%, rgb(29, 29, 29) 57%, rgb(52, 52, 52) 57%, rgb(52, 52, 52) 58%, rgb(75, 75, 75) 58%, rgb(75, 75, 75) 79%, rgb(97, 97, 97) 79%, rgb(97, 97, 97) 93%, rgb(120, 120, 120) 93%, rgb(120, 120, 120) 95%, rgb(143, 143, 143) 95%, rgb(143, 143, 143) 100%), linear-gradient(180deg, rgb(6, 6, 6) 0%, rgb(6, 6, 6) 6%, rgb(29, 29, 29) 6%, rgb(29, 29, 29) 57%, rgb(52, 52, 52) 57%, rgb(52, 52, 52) 58%, rgb(75, 75, 75) 58%, rgb(75, 75, 75) 79%, rgb(97, 97, 97) 79%, rgb(97, 97, 97) 93%, rgb(120, 120, 120) 93%, rgb(120, 120, 120) 95%, rgb(143, 143, 143) 95%, rgb(143, 143, 143) 100%), linear-gradient(225deg, rgb(6, 6, 6) 0%, rgb(6, 6, 6) 6%, rgb(29, 29, 29) 6%, rgb(29, 29, 29) 57%, rgb(52, 52, 52) 57%, rgb(52, 52, 52) 58%, rgb(75, 75, 75) 58%, rgb(75, 75, 75) 79%, rgb(97, 97, 97) 79%, rgb(97, 97, 97) 93%, rgb(120, 120, 120) 93%, rgb(120, 120, 120) 95%, rgb(143, 143, 143) 95%, rgb(143, 143, 143) 100%), linear-gradient(90deg, rgb(6, 6, 6) 0%, rgb(6, 6, 6) 6%, rgb(29, 29, 29) 6%, rgb(29, 29, 29) 57%, rgb(52, 52, 52) 57%, rgb(52, 52, 52) 58%, rgb(75, 75, 75) 58%, rgb(75, 75, 75) 79%, rgb(97, 97, 97) 79%, rgb(97, 97, 97) 93%, rgb(120, 120, 120) 93%, rgb(120, 120, 120) 95%, rgb(143, 143, 143) 95%, rgb(143, 143, 143) 100%), linear-gradient(180deg, rgb(8, 8, 8), rgb(221, 221, 221));
    background-blend-mode: overlay, overlay, overlay, overlay, normal;
    box-sizing: border-box;
    padding: 7.5% 10%;
}

.link__contact__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.link__contact__info,
.link__company__info {
    width: 50%;
    height: 100%;
    position: relative;
}

.link__company__info {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 5% 0%;
}

.link__contact__card__background {
    box-sizing: border-box;
    width: 32.5vmax;
    height: 62vmin;
    background: linear-gradient(135deg, #e9f7f8 25%, #14a38e);
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    z-index: 1;
}

.link__contact__card {
    box-sizing: border-box;
    width: calc(32.5vmax - 2px);
    height: calc(62vmin - 2px);
    border: 1px solid transparent;
    background-color: rgba(0, 0, 0, .85);
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(1px, -50%);
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.link__company__title {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.link__company__detail {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 7.5vmin;
}

.link__company__detail span {
    font-family: 'Electrolize', sans-serif;
    letter-spacing: 1.5px;
    color: rgba(255, 255, 255, .7);
    font-size: 1.5vmin;
    white-space: nowrap;
    user-select: none;
    margin-bottom: 2.5vmin;
}

.link__company__title span {
    position: relative;
    font-family: 'Electrolize', sans-serif;
    letter-spacing: 1.5px;
    color: rgba(255, 255, 255, .7);
    font-size: 4.5vmin;
    white-space: nowrap;
    user-select: none;
}

.link__company__title span::before {
    content: '';
    position: absolute;
    bottom: 0%;
    left: 0%;
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, #e9f7f8 25%, #14a38e);
}

.link__company__title span::after {
    content: '';
    position: absolute;
    bottom: -10%;
    right: 0%;
    width: 350%;
    height: 1px;
    background: linear-gradient(to left, #e9f7f8 25%, #14a38e);
}

.link__company__find__us {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.link__company__detail a {
    text-decoration: none;
    color: inherit;
}

.link__company__detail a:visited {
    text-decoration: none;
    color: inherit;
}

.link__find__fb,
.link__find__yt,
.link__find__ln {
    cursor: pointer;
    user-select: none;
    position: relative;
    transition: .2s linear;
}

.link__find__fb:hover {
    color: #1877F2;
    transition: .2s linear;
}

.link__find__yt:hover {
    color: #ff4e45;
    transition: .2s linear;
}

.link__find__ln:hover {
    color: #0a66c2;
    transition: .2s linear;
}

.link__copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 5%;
    position: absolute;
    bottom: 0%;
    left: 0%;
    user-select: none;
}

.link__copyright span {
    color: rgba(255, 255, 255, 1);
    font-size: 1.5vmin;
    letter-spacing: 1px;
    font-family: 'Electrolize', sans-serif;
}

// colors
$input-background: rgba(57, 63, 84, 0.8);
$input-text-inactive: #bdbdbd;
$input-text-active: #BFD2FF;

// gradient animation
@keyframes gradient {
    0% {
        background-position: 0 0
    }

    100% {
        background-position: 100% 0
    }
}

.link__contact__input {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 80%;
    // max-width: 420px;
    border-radius: 2px;
    padding: 1.2rem 1rem;
    box-sizing: border-box;

    &:after {
        content: "";
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 9;
        height: 2px;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        background-position: 0% 0%;
        background: linear-gradient(to right, #B294FF, #57E6E6, #FEFFB8, #57E6E6, #B294FF, #57E6E6);
        background-size: 500% auto;
        animation: gradient 3s linear infinite;
    }
}

.link__contact__input input {
    flex-grow: 1;
    color: $input-text-active;
    font-size: 2vmin;
    line-height: 2vmin;
    vertical-align: middle;
    background-color: transparent;
    font-family: 'Electrolize', sans-serif;
    outline: none;
    border: none;

    &::-webkit-input-placeholder {
        font-family: 'Electrolize', sans-serif;
        color: $input-text-inactive;
    }
}

.link__contact__send {
    box-sizing: border-box;
    width: 35%;
    height: 5.5%;
    background-color: transparent;
    border: 1px solid #57E6E6;
    border-radius: 5px;
    transition: all .15s ease-in-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.link__contact__send span {
    font-size: 1.5vmin;
    letter-spacing: 1px;
    font-family: 'Electrolize', sans-serif;
    color: #57E6E6;
}

.link__contact__send:hover {
    box-shadow: 0 0 10px 0 #57E6E6 inset, 0 0 20px 2px #57E6E6;
    border: 1px solid #57E6E6;
}