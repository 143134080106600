.link__subject__wrap {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.link__subject__front {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 2.5%;
    // background: rgba(0, 0, 0, .1);
}

.link__subject__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 3.5vmin;
}

.link__subject__title__logo {
    height: 100%;
}

.link__subject__title__logo svg {
    height: 100%;
    fill: white;
}

.link__subject__title__menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.link__subject__title__menu .menu__bar {
    width: 1.5vmax;
    height: .25vmin;
    background-color: #fff;
    margin-bottom: 5.5px;
}

.link__subject__title__menu .menu__bar:nth-child(3) {
    margin-bottom: 0px;
}

.link__subject__content {
    display: block;
    width: 100%;
    height: calc(100% - 3.5vmin - 1px);
    position: relative;
    box-sizing: border-box;
    padding: 2.5% 0%;
}

.link__subject__content__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    box-sizing: border-box;
    padding: 2.5% 0%;
    min-height: 65vh;
}

.link__subject__content__list::before {
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    height: 1px;
    content: '';
    background: #fff;
    background-position: 0% 0%;
    background: linear-gradient(to left, #B294FF, #57E6E6, #FEFFB8, #57E6E6, #B294FF, #57E6E6);
    background-size: 500% auto;
    animation: color-run 3s linear infinite;
}

.link__subject__content__list::after {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50%;
    height: 1px;
    content: '';
    background: #fff;
    background-position: 0% 0%;
    background: linear-gradient(to right, #B294FF, #57E6E6, #FEFFB8, #57E6E6, #B294FF, #57E6E6);
    background-size: 500% auto;
    animation: color-run 3s linear infinite;
}

@keyframes color-run {
    0% {
        background-position: 0 0
    }

    100% {
        background-position: 100% 0
    }
}

.link__subject__content__detail {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% / 3);
    height: 10vmin;
    cursor: pointer;
}

.link__subject__content__detail__square {
    width: 7.5px;
    height: 7.5px;
    margin-right: 7.5px;
    background-color: rgba(255, 255, 255, .5);
    transition: .5s linear;
}

.link__subject__content__detail span {
    font-size: 2vmin;
    letter-spacing: 1px;
    font-family: 'Electrolize', sans-serif;
    color: rgba(255, 255, 255, .5);
    position: relative;
    transition: .5s linear;
}

.link__subject__content__detail:hover>.link__subject__content__detail__square {
    background-color: rgba(255, 255, 255, 1);
    transition: .5s linear;
}

.link__subject__content__detail:hover span {
    color: rgba(255, 255, 255, 1);
    transition: .5s linear;
}

.link__subject__background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    // background: linear-gradient(135deg, #1489a3 20%, #f20c0c 50%, #000 80%);
}

.link__subject__background__title {
    position: absolute;
    bottom: 5%;
    left: 5%;
    transform: translate(-5%, 5%);
}

// .link__subject__background__title::after {
//     content: '';
//     width: 30vw;
//     height: 1px;
//     background-color: rgba(255, 255, 255, .75);
//     position: absolute;
//     top: 50%;
//     left: 120%;
//     transform: translateY(-50%);
// }

.link__subject__background__title span {
    font-size: 7.5vmin;
    letter-spacing: 5.5px;
    font-weight: 900;
    font-family: 'Electrolize', sans-serif;
    color: #fff;
    animation: breathing-text 3s linear alternate-reverse infinite;
    text-transform: uppercase;
}

@keyframes breathing-text {
    from {
        opacity: .2;
    }

    to {
        opacity: .5;
    }
}

$CubeSize: 5vmax;
$HCH: $CubeSize/2;


.link__cube__animation {
    position: absolute;
    right: 5%;
    bottom: 5%;
    width: $CubeSize;
    height: $CubeSize;
    perspective: 2000px;
    perspective-origin: 50% -15vmax;
    transform: translate(5%, 5%);
}

.link__cube {
    transform-style: preserve-3d;
    animation: cube-rotate 10s infinite linear;
}

.link__cube div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    width: $CubeSize;
    height: $CubeSize;
    font-size: 1.35rem;
    letter-spacing: 2px;
    font-family: 'Electrolize', sans-serif;
    text-align: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(255, 255, 255, .5);
    backface-visibility: none;
    box-shadow: inset 5px 5px 10px rgba(255, 255, 255, .1), inset 5px 5px 20px rgba(255, 255, 255, .1), inset -5px -5px 10px rgba(255, 255, 255, .1), inset -5px -5px 20px rgba(255, 255, 255, .1);
}

.link__cube__outside {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.link__cube__outside svg {
    fill: #fff;
}

.link__cube__inside {
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: .5;
}

.link__cube__outside span {
    color: white;
}

.link__cube__front {
    position: relative;
    transform: translateZ($HCH);
}

.link__cube__back {
    background: rgba(0, 0, 0, .75);
    transform: rotateY(180deg) translateZ($HCH);
}

.link__cube__top {
    background: rgba(0, 0, 0, .75);
    transform: rotateX(90deg) translateZ($HCH);
}

.link__cube__bottom {
    background: rgba(0, 0, 0, .75);
    transform: rotateX(-90deg) translateZ($HCH);
}

.link__cube__left {
    background: rgba(0, 0, 0, .75);
    transform: rotateY(-90deg) translateZ($HCH);
}

.link__cube__right {
    background: rgba(0, 0, 0, .75);
    transform: rotateY(90deg) translateZ($HCH);
}

@keyframes cube-rotate {
    0% {
        transform: rotateY(0);
    }

    100% {
        transform: rotateY(360deg);
    }
}